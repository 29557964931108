<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-users' }">{{
            $t("MENU.ITEM.ACCOUNTS.USERS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingUser"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>

          <UserForm :form="form" :loading="firstLoader" />

          <h2>Парола</h2>

          <v-divider></v-divider>
          <template v-if="!firstLoader">
            <b-form-row v-if="'password' in form">
              <b-col>
                <b-form-group
                  v-bind:id="'password-password-input'"
                  v-bind:label="$t('FORM_LABELS.PASSWORD')"
                  v-bind:label-for="'password-password-input'"
                >
                  <FormInput
                    :id="'password-password-input'"
                    :type="'text'"
                    :name="'password'"
                    :required="'true'"
                    :feedback_id="'password-password-feedback'"
                    :i18n="{}"
                    :validations="{}"
                    :validateState="validateState('password')"
                    v-model="form.password"
                  >
                    <template v-slot:button-append>
                      <div class="input-group-append">
                        <span
                          @click="generateRandomPassword()"
                          class="btn btn-primary font-weight-bolder mr-2"
                        >
                          <v-icon dark>mdi-restart</v-icon>
                          {{ $t("BUTTON.GENERATE_PASSWORD") }}
                        </span>
                      </div>
                    </template>
                  </FormInput>
                </b-form-group>
              </b-col>
            </b-form-row>
          </template>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
          <v-overlay :value="!firstLoader && isLoadingUser">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingUser"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_USER } from "@/modules/accounts/user/store/user.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

import UserForm from "../components/UserForm.vue";

export default {
  mixins: [validationMixin, formBuilderMixin],
  components: {
    KTCard,
    FormBuilder,
    FormInput,
    SaveButtonDropdown,
    UserForm
  },
  validations() {
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    const passwordValidations = {
      required: true,
      minLength: 6,
      params: { name: "Password" }
    };
    if ("password" in this.form) {
      tmpValidationObject.form["password"] = this.setItemValidations(
        passwordValidations
      );
      return tmpValidationObject;
    } else {
      return tmpValidationObject;
    }
  },
  data() {
    return {
      errors: {},
      firstLoader: true
    };
  },
  computed: {
    ...mapGetters(["isLoadingUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "switch",
            groupId: "user-active-group",
            id: "user-active-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "active-live-feedback",
            colSm: "auto",
            divider: true,
            default: true,
            i18n: {
              label: "FORM_LABELS.ACTIVE",
              placeholder: "FORM_PLACEHOLDERS.ACTIVE",
              validation: "FORM.VALID_ACTIVE"
            },
            validations: {
              required: true
            },
            model: "active"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-email-input",
            groupId: "user-email-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: true,
            i18n: {
              label: "FORM_LABELS.EMAIL",
              placeholder: "FORM_PLACEHOLDERS.EMAIL",
              validation: "FORM.VALID_EMAIL"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "email"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-firstName-input",
            groupId: "user-firstName-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.FIRST_NAME",
              placeholder: "FORM_PLACEHOLDERS.FIRST_NAME",
              validation: "FORM.VALID_FIRST_NAME"
            },
            validations: {
              required: true,
              minLength: 2,
              params: { name: "First Name" }
            },
            translatable: false,
            model: "firstName"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-lastName-input",
            groupId: "user-lastName-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.LAST_NAME",
              placeholder: "FORM_PLACEHOLDERS.LAST_NAME",
              validation: "FORM.VALID_LAST_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "lastName"
          }
        ]
      };
    }
  },
  mounted() {
    this.setFormPassword();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Потребители", route: { name: "list-users" } },
      { title: "Добавяне на потребител" }
    ]);
    this.generateFormOutOfSchemaJson(this.schemaJson);
    this.firstLoader = false;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSave(next) {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Моля попълнете всички задължителни полета"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_USER, this.form)
        .then(data => {
          this.errors = data.data.errors;
          let itemEdit = data.data;
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-user",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-user" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-users" });
          }
        })
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    },
    setFormPassword() {
      this.$set(this.form, "password", "");
    },
    generateRandomPassword() {
      var randomString = Math.random()
        .toString(36)
        .slice(-8);
      this.form.password = randomString;
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
